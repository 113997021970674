import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders } from './Headers';

const Coupon = {

    Generate: (data, successCb, failCb) => {
        axios.post(`/coupon/generate`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Approve: (coupon_id, successCb, failCb) => {
        axios.post(`/coupons/${coupon_id}/approve`, null, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Reject: (coupon_id, successCb, failCb) => {
        axios.post(`/coupons/${coupon_id}/reject`, null, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Report: (branch_id, data, successCb, failCb) => {
        axios.post(`/branch/${branch_id}/coupons/report`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    UsageReport: (branch_id, data, successCb, failCb) => {
        axios.post(`/branch/${branch_id}/coupons/usage_report`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    FinancialReport: (branch_id, data, successCb, failCb) => {
        axios.post(`/branch/${branch_id}/coupons/financial_report`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    CouponGenerationReport: (branch_id, data, successCb, failCb) => {
        axios.post(`/branch/${branch_id}/coupons/coupon_generation_report`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    ApprovalReport: (branch_id, data, successCb, failCb) => {
        axios.post(`/branch/${branch_id}/coupons/approval_report`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },
};

export default Coupon;
