import React, {Suspense, useEffect, useState} from 'react';
import lazy from "react-lazy-with-preload";
import {Switch, Route, useRouteMatch, useHistory, useLocation} from 'react-router-dom';
import PreLoading from "../components/PreLoading";
import {closeDestroyCartBubble, closeStoreCartBubble, closeUpdateCartBubble} from "../redux/cart/actionCreator";
import {useDispatch, useSelector} from "react-redux";
import GuestRoute from "./GuestRoute";
import AuthRoute from "./AuthRoute";
import {updateAuthProfile} from "../redux/authentication/actionCreator";
import Orders from "../pages/User/Profile/Orders";
import ActiveOrders from "../pages/User/Profile/ActiveOrders";
import OfflineOrders from "../pages/User/Profile/OfflineOrders";
import Profile from "../pages/User/Profile/Profile";
import Order from "../pages/User/Order/Order";
import MenuSkeleton from "../components/skeletons/MenuSkeleton";
import Cart from "../pages/Cart/Cart";
import CheckoutHttp from '../http/Checkout';
import Payment from "../pages/Payment/Payment";
import ForgotPassword from "../pages/User/ForgotPassword";
import ResetPassword from "../pages/User/ResetPassword";
import Contact from "../pages/Contact/Contact";
import Privacy from "../pages/Contact/Privacy";
import {ListActiveOrders, ValidateCheckout} from "../redux/checkout/actionCreator";
import Category from "../pages/Menu/Category";
import FoodCustomize from "../pages/Food/FoodCustomize";
import UnderConstructions from "../pages/UnderConstructions";
import {CheckVersion} from "../redux/settings/actionCreator";
import DashboardRoutes from "./DashboardRoutes";
import HiringForm from "../pages/HiringForm";
import Invitation from "../pages/User/Invitation";
import About from "../pages/Contact/About";
import CustomerClub from "../pages/User/Profile/CustomerClub";
import PrintCheckout from "../pages/PrintCheckout/PrintCheckout";
import AcceptInvitation from "../pages/User/AcceptInvitation";
import VerifyEmail from "../pages/User/VerifyEmail";
import ConfirmVendorOrder from "../pages/User/Dashboard/Inventory/VendorOrders/ConfirmVendorOrder";

const ReactLazyPreload = (importStatement) => {
    const Component = React.lazy(importStatement);
    Component.preload = importStatement;
    return Component;
};

const Home = lazy(() => import('../pages/Home'));
const Food = lazy(() => import('../pages/Food/Food'));
const Location = lazy(() => import('../pages/Location'));
const Checkout = lazy(() => import('../pages/Checkout/Checkout'));
const Login = lazy(() => import('../pages/User/Login'));
const Register = lazy(() => import('../pages/User/Register'));
const ServerDown = lazy(() => import('../pages/Server/ServerDown'));
const PageNotFound = lazy(() => import('../pages/Server/404'));
const Categories = ReactLazyPreload(() => import('../pages/Menu/Categories'));
const Logout = ReactLazyPreload(() => import('../pages/User/Logout'));

const BaseRoutes = () => {
    const { path } = useRouteMatch();
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const dispatch = useDispatch();
    const history = useHistory();
    const menu = useSelector(state => state.menu.index_menu);
    const [loadedSend, setLoadedSend] = useState(false);
    const location = useLocation();

    
    useEffect(() => {
        //console.log("location:", location);
        dispatch(CheckVersion(history, location));
    }, [location.pathname]);

    useEffect(() => {
        if(isLoggedIn) {
            const orders = JSON.parse(localStorage.getItem('order_uids')) || [];
            if(orders && orders.length > 0) {
                CheckoutHttp.TakeOfflineOwnership(orders,(response) => {
                }, (err) => {
                });
            }
            localStorage.removeItem('order_uids');
        }
    }, [isLoggedIn]);

    const sendLoadedSignal = () => {
        console.log("sendLoadedSignal");
        window.sendMessageToSwift('loaded');
    }

    const tokenOwnership = () => {
        const device_token = localStorage.getItem('device_token');
        const device_uuid = localStorage.getItem('device_uuid');
        CheckoutHttp.TokenOwnership(device_uuid, device_token,(response) => {
        }, (err) => {
        });
    }

    useEffect(() => {
        dispatch(ListActiveOrders());
    }, []);

    useEffect(()=> {
        setTimeout(sendLoadedSignal, 500);
    }, []);

    const handleIncomingMessages = (message) => {
        let data = message.data;

        if (data && typeof data === "string" &&  data.includes(":"))
        {
            const parts = data.split(":");

            if (parts[0] == "command") {
                const command = parts[1];
                const command_parts = command.split("|");
                if(command_parts[0] == "redirect") {
                    history.push(command_parts[1]);
                }
            }

            if (parts[0] == "redirect") {
                history.push("/" + parts[1]);
            }
            if (parts[0] == "token") {
                localStorage.setItem('device_token', parts[1]);
                window.sendMessageToSwift('devicetokenloaded')
                tokenOwnership();
            }

            if (parts[0] == "refresh") {
                dispatch(ListActiveOrders());
            }

            if (parts[0] == "hard-refresh") {
                dispatch(ListActiveOrders());
                history.push("/order/"+parts[1]);
            }

            if (parts[0] == "uuid") {
                localStorage.setItem('device_uuid', parts[1]);
                window.sendMessageToSwift('deviceuuidloaded')
            }

            if (parts[0] == "type") {
                localStorage.setItem('device_type', parts[1]);
                window.sendMessageToSwift('devicetypeloaded')
            }

            if(parts[0] == 'validate') {
                let id = parts[1];
                history.push(`/order/${id}/validate`)
            }

            if(parts[0] == 'applepay') {
                localStorage.setItem("applepay", parts[1]);
            }
        }
    }


    const versionUpdateHandler = (event) => {
        if (event.key === 'version_update') {
            const currentVersion = localStorage.getItem('version');
            const apiVersion = event.newValue;
            if (currentVersion !== apiVersion) {
                console.log("Version update detected. Refreshing the page.");
                window.removeEventListener('storage', versionUpdateHandler);
                window.location.reload();
            }
        }
    };


    useEffect(() => {
        window.addEventListener('storage', versionUpdateHandler);
        window.addEventListener("message", handleIncomingMessages);
        return () => {
            window.removeEventListener('storage', versionUpdateHandler);
            window.removeEventListener("message", handleIncomingMessages);
        }
    }, []);



    useEffect(() => {
        dispatch(closeStoreCartBubble());
        dispatch(closeUpdateCartBubble());
        dispatch(closeDestroyCartBubble());
    });

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(updateAuthProfile());
        }
    },
    [isLoggedIn]);

    return (
        <Switch>
            <Suspense
                fallback={
                    <div className="spin">
                        <MenuSkeleton />
                    </div>
                }
            >
                <PreLoading>
                    <Categories/>
                </PreLoading>

                {global.config.underConstructions ?
                    <Route path="/" component={UnderConstructions} />
                :
                    <>
                        <Route exact path={`/print/checkout/:uuid`} component={PrintCheckout} />

                        <Route exact path="/" component={Home} />
                        <Route path={`/location`} component={Location} />
                        <Route path={`/contact`} component={Contact} />
                        <Route path={`/about`} component={About} />
                        <Route path={`/privacy`} component={Privacy} />
                        <Route path={`/menu`} component={Categories} />
                        <Route path={`/category/:slug`} component={Category}/>
                        <Route path={`/hiring-form`} component={HiringForm}/>

                        <Route exact path={`/cart`} component={Cart} />
                        <Route path={`/cart/:uid`} component={FoodCustomize} />
                        <Route exact path={`/food/:slug`} component={Food} />
                        <Route exact path={`/food/:slug/customize`} component={FoodCustomize}/>

                        <Route path={`/checkout`} component={Checkout} />
                        <Route path={`/payment`} component={Payment} />
                        <GuestRoute path={`/login`} component={Login} />
                        <GuestRoute path={`/forgot-password`} component={ForgotPassword} />
                        <GuestRoute path={"/reset-password/:token/:email"} component={ResetPassword}/>
                        <GuestRoute path={"/accept-invitation/:token/:email"} component={AcceptInvitation}/>
                        <GuestRoute path={`/register`} component={Register} />
                        <Route exact path={`/resend-verification-email`} component={VerifyEmail} />
                        <Route exact path={`/verify-email/:data`} component={VerifyEmail} />
                        <AuthRoute path={`/profile`} component={Profile} />
                        <AuthRoute path={`/club`} component={CustomerClub} />
                        <GuestRoute exact path={`/orders/offline`} component={OfflineOrders} />
                        <Route exact path={`/orders/active`} component={ActiveOrders} />
                        <Route exact path={`/order/:uid/validate`} component={Order} />
                        <Route exact path={`/order/:uid`} component={Order} />
                        <AuthRoute exact path={`/orders`} component={Orders} />
                        <AuthRoute path={`/logout`} component={Logout} />
                        <Route exact path={`/server-down`} component={ServerDown} />
                        <Route path={`/404`} component={PageNotFound} />

                        <Route exact path={`/invitation/:token/:username`} component={Invitation} />

                        <Route exact path="/inventory/vendor-orders/:uuid/confirm" component={ConfirmVendorOrder} />

                        <AuthRoute path={`/dashboard`} component={DashboardRoutes} />


                    </>
                }

            </Suspense>
        </Switch>
    );
};

export default BaseRoutes;