import React, { useState, useEffect } from 'react';
import { Table, Pagination, Select, notification } from 'antd';
import DefaultContainer from '../../../../../components/DefaultContainer';
import BranchInventoryGroup from "../../../../../http/BranchInventoryGroup";
import { useParams } from "react-router-dom";

const BranchDailyRecords = () => {
    const { branchId } = useParams();
    const [records, setRecords] = useState({});
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(3);
    const [totalDays, setTotalDays] = useState(0);

    useEffect(() => {
        fetchDailyRecords();
    }, [page, perPage]);

    const fetchDailyRecords = () => {
        setLoading(true);
        BranchInventoryGroup.GetDailyRecords(
            branchId,
            page,
            perPage,
            (response) => {
                setRecords(response.data.data.records);
                setTotalDays(response.data.data.total_days);
                setLoading(false);
                /*
                notification['success']({
                    message: 'Success!',
                    description: 'Daily records loaded successfully.',
                });
                 */
            },
            (err) => {
                setLoading(false);
                notification['error']({
                    message: 'Error!',
                    description: err.response?.data?.message || 'Failed to load daily records',
                });
            }
        );
    };

    const handlePageChange = (page) => {
        setPage(page);
    };

    const handlePerPageChange = (value) => {
        setPerPage(value);
        setPage(1); // Reset to first page when changing per page
    };

    // Columns for the table
    const columns = [
        {
            title: 'Inventory Group',
            dataIndex: ['branch_inventory_group', 'name'],
            key: 'group_name',
            render: (text, record) => (
                <div className={"text-2xl"}>
                    {text}
                </div>
            )
        },
        {
            title: 'Received Amount',
            dataIndex: 'received_amount',
            key: 'received_amount',
        },
        {
            title: 'Start Day Inventory',
            dataIndex: 'start_day_inventory',
            key: 'start_day_inventory',
        },
        {
            title: 'End Day Inventory',
            dataIndex: 'end_day_inventory',
            key: 'end_day_inventory',
        },
        {
            title: 'End Day Confirmation',
            dataIndex: 'end_day_inventory_confirmation',
            key: 'end_day_inventory_confirmation',
        },
        {
            title: 'Used Amount',
            dataIndex: 'used_amount',
            key: 'used_amount',
        },
        {
            title: 'Sold Amount',
            dataIndex: 'sold_amount',
            key: 'sold_amount',
        },
        {
            title: 'Wasted Amount',
            dataIndex: 'wasted_amount',
            key: 'wasted_amount',
        },
        {
            title: 'Waste Percentage',
            key: 'waste_percentage',
            render: (text, record) => (
                <div>
                    {((record.wasted_amount / record.used_amount) * 100).toFixed(2)}%
                </div>
            ),
        },
        {
            title: 'Difference',
            key: 'difference',
            render: (text, record) => (
                <div>
                    {(record.end_day_inventory - record.end_day_inventory_confirmation).toFixed(2)}
                </div>
            ),
        },
    ];

    // Helper function to render each day's records
    const renderRecordsForDay = (date, dayRecords) => (
        <div key={date} className="mb-6">
            <h3 className="text-lg font-bold mb-2">Date: {date}</h3>
            <Table
                columns={columns}
                dataSource={dayRecords}
                rowKey="id"
                pagination={false} // No pagination here, we paginate by days
            />
        </div>
    );

    return (
        <DefaultContainer>
            <div className="mb-4 flex justify-between items-center">
                <Select defaultValue={3} onChange={handlePerPageChange} className="mr-2">
                    <Select.Option value={1}>1 day per page</Select.Option>
                    <Select.Option value={3}>3 days per page</Select.Option>
                    <Select.Option value={5}>5 days per page</Select.Option>
                </Select>

            </div>
            {Object.keys(records).map((date) => renderRecordsForDay(date, records[date]))}
            <Pagination
                current={page}
                pageSize={perPage}
                total={totalDays}
                onChange={handlePageChange}
                className="mt-4"
            />
        </DefaultContainer>
    );
};

export default BranchDailyRecords;
