import axios from 'axios';
import {getAuthenticationHeaders} from './Headers';

const Checkout = {
    Store: (successCb, failCb) => {
        let cart_items = JSON.parse(localStorage.getItem('cart_items'));
        if (cart_items === null) {
            console.log("Error! cart is empty!");
            failCb("Your cart is empty!");
        }
        else {
            axios.post('/checkout', {"items": cart_items}, getAuthenticationHeaders()).then(response => {
                successCb(response);
            }).catch(err => {
                failCb(err);
            });
        }
    },

    ApplyCoupon: (checkout_uuid, code, successCb, failCb) => {
        axios.post(`/checkout/${checkout_uuid}/apply_coupon`, {code}, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },

    ClearCoupon: (checkout_uuid, successCb, failCb) => {
        axios.post(`/checkout/${checkout_uuid}/clear_coupon`, null, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },

    CreatePayment: (checkout_uuid, data, successCb, failCb) => {
        axios.post(`/checkout/${checkout_uuid}/web/pay`, data, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },

    WebStripePay: (checkout_uuid, payment_method, successCb, failCb) => {
        axios.post(`/checkout/${checkout_uuid}/web/stripe/pay`,{payment_method}, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },

    StripeIntent: async (checkout_uuid, successCb, failCb) => {
        await axios.post(`/checkout/${checkout_uuid}/stripe/intent`, {paymentMethodType: 'card', currency: 'cad'}, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },

    Validate: (uuid, successCb, failCb) => {
        axios.get(`/checkout/${uuid}/validate`, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },

    TokenOwnership: (device_uuid, device_token, successCb, failCb) => {
        axios.post(`/checkouts/token_ownership`, {device_uuid, device_token}, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },

    TakeOfflineOwnership: (order_uids, successCb, failCb) => {
        axios.post(`/checkouts/take_offline_orders_ownership`, {order_uids}, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },
};

export default Checkout;
