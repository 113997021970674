import actions from './actions';
import Menu from '../../http/Menu';

const {
    menuIndexBegin,
    menuIndexSuccess,
    menuIndexErr,
    updateMenu
} = actions;

const listMenu = (history) => {
    return async dispatch => {
        dispatch(menuIndexBegin());
        Menu.Index((response) => {
            console.log("Menu:", response.data.data);
            dispatch(menuIndexSuccess(response.data.data.categories, response.data.data.options, response.data.data.images, response.data.data.translations, response.data.data.public_path))
        }, (err) => {
            if (!err.response) {
                history.push('/server-down')
            } else {
                dispatch(menuIndexErr(err?.response?.data?.message ?? "No internet connection!"))
            }
        });
    };
};

const UpdateMenu = (categories, foods, options) => {
    return async dispatch => {
        dispatch(updateMenu(categories, foods, options));
    }
}

export {listMenu, UpdateMenu};
