import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Input, notification, Tag} from "antd";
import Amount from "../GeneratePresets/Amount";
import DiscountPreset from "../../../../../http/DiscountPreset";
import Coupon from "../../../../../http/Coupon";
import CouponComponent from "./CouponComponent";
import {RiCalendarEventLine, RiMoneyDollarCircleLine, RiPercentLine, RiStopCircleLine} from "@remixicon/react";

const CouponGeneratePresetComponent = ({couponGeneratePreset, reload, short=false, paste, checkout_mode=false}) => {

    const [tweakMode, setTweakMode] = useState(false);
    const [generating, setGenerating] = useState(false);

    const [requireAmount, setRequireAmount] = useState(false);
    const [amount, setAmount] = useState(null);

    const [requirePercentBalance, setRequirePercentBalance] = useState(false);
    const [percentBalance, setPercentBalance] = useState(null);

    const [requireMaxTotalPrice, setRequireMaxTotalPrice] = useState(false);
    const [maxTotalPrice, setMaxTotalPrice] = useState(null);
    const [unlimitedMaxTotalPrice, setUnlimitedMaxTotalPrice] = useState(null);

    const [requireMaxCouponPrice, setRequireMaxCouponPrice] = useState(false);
    const [maxCouponPrice, setMaxCouponPrice] = useState(null);
    const [unlimitedMaxCouponPrice, setUnlimitedMaxCouponPrice] = useState(null);

    const [requireOnlyOneBurger, setRequireOnlyOneBurger] = useState(false);
    const [onlyOneBurger, setOnlyOneBurger] = useState(null);

    const [requireExpireHours, setRequireExpireHours] = useState(false);
    const [expireHours, setExpireHours] = useState(null);
    const [unlimitedExpireHours, setUnlimitedExpireHours] = useState(null);

    const [validationFields, setValidationFields] = useState(null);
    const [canGenerate, setCanGenerate] = useState(true);
    const [generatedCoupon, setGeneratedCoupon] = useState(null);

    const [requireFullName, setRequireFullName] = useState(false);
    const [fullName, setFullName] = useState("");

    const onGenerate = () => {

        setGeneratedCoupon(null);

        console.log("-------------");
        setTweakMode(true);

        let canGenerateNow = true;

        if (couponGeneratePreset.requires_recipient_name && !fullName) {
            console.log("Enter recipient name");
            canGenerateNow = false;
            setRequireFullName(true);
        }

        if (couponGeneratePreset.max_amount > couponGeneratePreset.min_amount || couponGeneratePreset.min_amount == 0) {
            if (amount === null) {
                console.log("Enter amount");
                canGenerateNow = false;
                setRequireAmount(true);
            }
        } else {
            setAmount(couponGeneratePreset.min_amount);
        }

        if (couponGeneratePreset.type == "balance") {

        } else {
            if (couponGeneratePreset.max_percent_balance > couponGeneratePreset.min_percent_balance) {
                if (percentBalance === null) {
                    console.log("Enter Percent Balance");
                    canGenerateNow = false;
                    setRequirePercentBalance(true);
                }
            } else {
                setPercentBalance(couponGeneratePreset.min_percent_balance);
            }
        }

        if (couponGeneratePreset.max_total_price > couponGeneratePreset.min_total_price) {
            if (maxTotalPrice === null) {
                console.log("Enter max total price");
                canGenerateNow = false;
                setRequireMaxTotalPrice(true);
            }
        } else {
            setMaxTotalPrice(couponGeneratePreset.min_total_price);
        }

        if (couponGeneratePreset.max_coupon_price > couponGeneratePreset.min_coupon_price) {
            if (maxCouponPrice === null) {
                console.log("Enter max coupon price");
                canGenerateNow = false;
                setRequireMaxCouponPrice(true);
            }
        } else {
            setMaxCouponPrice(couponGeneratePreset.min_coupon_price);
        }

        if (couponGeneratePreset.max_only_one_burger > couponGeneratePreset.min_only_one_burger) {
            if (onlyOneBurger === null) {
                console.log("Enter require one burger only value");
                canGenerateNow = false;
                setRequireOnlyOneBurger(true);
            }
        } else {
            setOnlyOneBurger(couponGeneratePreset.min_only_one_burger);
        }

        if (couponGeneratePreset.max_expire_hours > couponGeneratePreset.min_expire_hours) {
            if (expireHours === null) {
                console.log("Enter expire hours");
                canGenerateNow = false;
                setRequireExpireHours(true);
            }
        } else {
            setExpireHours(couponGeneratePreset.min_expire_hours);
        }

        setCanGenerate(canGenerateNow);

        if (canGenerateNow) {
            setGenerating(true);
        }
    }

    useEffect(() => {
        if (generating) GenerateCoupon();
    }, [generating])

    const GenerateCoupon = () => {
        const data = {
            'coupon_generate_preset_id': couponGeneratePreset.id,
            'amount': amount,
            'percent_balance': percentBalance,
            'max_total_price': maxTotalPrice,
            'max_coupon_price': maxCouponPrice,
            'one_item': onlyOneBurger,
            'expire_hours': expireHours,
            'full_name': fullName // Add full_name to data
        };
        console.log(data);

        Coupon.Generate(data, (response) => {
            notification['success']({
                message: 'Success!',
                description: response.data.message,
            });
            setValidationFields(null);
            setGenerating(false);
            const c = response.data.data;
            setGeneratedCoupon(c);
            if(paste)
                paste(c.code);
            if(!checkout_mode)
                reload();
        }, (err) => {
            notification['error']({
                message: 'Error!',
                description: err.response?.data?.message,
            });
            setValidationFields(err.response?.data?.errors);
            setGenerating(false);
        });
    }

    return (
        <div className={`mb-2 border border-gray-300 shadow-md w-full px-4 py-2 flex flex-col bg-white rounded-lg box`}>
            <div className={"flex flex-col md:flex-row items-center text-xs w-full"}>
                <div className={`uppercase ${checkout_mode ? 'w-full' : 'mr-2'} font-bold ${!couponGeneratePreset.status ? 'opacity-30' : ''}`}>
                    <div className={`${checkout_mode ? 'text-xl' : 'text-3xl'} font-bold patty flex flex-row items-center whitespace-nowrap`}>
                        {couponGeneratePreset.name} {!couponGeneratePreset.status ? <Tag color={'red'} style={{ marginLeft: "10px" }}>Disabled</Tag> : null}
                    </div>

                    {!short && (
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-1 mt-1">
                            {couponGeneratePreset.discount_preset ? (
                                <div className={"text-base font-bold flex flex-row items-center mr-4"}>
                                    <div>{couponGeneratePreset.discount_preset.name}</div>
                                    {couponGeneratePreset.employment_type !== "n/a" && (
                                        <div className={"text-sm text-white bg-gray-600 px-2 ml-2 rounded-sm"}>
                                            {couponGeneratePreset.employment_type}
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className={"text-lg mr-4"}>Preset: Any</div>
                                )}

                            <div className="flex items-center mr-4">
                                {couponGeneratePreset.type === "balance" ? (
                                    <RiMoneyDollarCircleLine className="mr-2 text-lg" />
                                ) : (
                                    <RiPercentLine className="mr-2 text-lg" />
                                )}
                                <span>Type: {couponGeneratePreset.type}</span>
                            </div>

                            {couponGeneratePreset.type === "balance" ? (
                                <div className="flex items-center mr-4">
                                    <RiMoneyDollarCircleLine className="mr-2 text-lg" />
                                    <span>Amount: <Amount min={couponGeneratePreset.min_amount} max={couponGeneratePreset.max_amount} sign={'$'} /></span>
                                </div>
                            ) : (
                                <>
                                    <div className="flex items-center mr-4">
                                        <RiPercentLine className="mr-2 text-lg" />
                                        <span>Discount Percent: <Amount sign={"%"} min={couponGeneratePreset.min_amount} max={couponGeneratePreset.max_amount} /></span>
                                    </div>
                                    <div className="flex items-center mr-4">
                                        <span>Times to be Used: <Amount min={couponGeneratePreset.min_percent_balance} max={couponGeneratePreset.max_percent_balance} /></span>
                                    </div>
                                </>
                            )}

                            <div className="flex items-center mr-4">
                                <RiMoneyDollarCircleLine className="mr-2 text-lg" />
                                <span>Max Total Price: <Amount min={couponGeneratePreset.min_total_price} max={couponGeneratePreset.max_total_price} sign={'$'} /></span>
                            </div>

                            <div className="flex items-center mr-4">
                                <RiMoneyDollarCircleLine className="mr-2 text-lg" />
                                <span>Max Coupon Price: <Amount min={couponGeneratePreset.min_coupon_price} max={couponGeneratePreset.max_coupon_price} sign={'$'} /></span>
                            </div>

                            {couponGeneratePreset.beer === 'none' && (
                                <div className="flex items-center text-red-700 mr-4">
                                    <RiStopCircleLine className="mr-2 text-lg" />
                                    <span>NO BEERS ARE ALLOWED ON THIS COUPON</span>
                                </div>
                            )}

                            <div className="flex items-center mr-4">
                                <RiCalendarEventLine className="mr-2 text-lg" />
                                <span>
                        {couponGeneratePreset.expiration_type === "hours" ? (
                            <>Expire Hours: <Amount min={couponGeneratePreset.min_expire_hours} max={couponGeneratePreset.max_expire_hours} /> HOURS</>
                        ) : (
                            <>Expire Date: {couponGeneratePreset.expiration_date}</>
                        )}
                        </span>
                            </div>

                            <div className="mr-4">
                                <strong>Frequency:</strong> {couponGeneratePreset.times_per_day} PER DAY, {couponGeneratePreset.times_per_week} PER WEEK
                            </div>

                            <div className="flex flex-row items-center mr-4">
                                Burger Setting:
                                {tweakMode && requireOnlyOneBurger ? (
                                    <div className="ml-2">
                                        <Checkbox onChange={(e) => setOnlyOneBurger(e.target.checked)} disabled={generating}>
                                            Only One Burger
                                        </Checkbox>
                                    </div>
                                ) : (
                                    <>
                                        {couponGeneratePreset.min_only_one_burger === 0 && couponGeneratePreset.max_only_one_burger === 0 ? (
                                            <>Coupon can be generated with multi burgers only.</>
                                        ) : null}
                                        {couponGeneratePreset.min_only_one_burger === 0 && couponGeneratePreset.max_only_one_burger === 1 ? (
                                            <>Coupon can be generated with both one burger or multi burgers.</>
                                        ) : null}
                                        {couponGeneratePreset.min_only_one_burger === 1 && couponGeneratePreset.max_only_one_burger === 1 ? (
                                            <>Coupon can be generated with one burger only.</>
                                        ) : null}
                                    </>
                                )}
                            </div>
                        </div>
                    )}

                    <div className={`w-full mb-1 ${checkout_mode ? '' : 'mt-4'}`}>
                        {requireFullName && (
                            <div className="mb-2">
                                <Input placeholder="Enter recipient's full name" value={fullName} onChange={(e) => setFullName(e.target.value)} disabled={generating} />
                            </div>
                        )}
                        {!canGenerate && <div className="text-red-400 pb-2">* Please fill all the required fields.</div>}
                        <Button type="primary" onClick={onGenerate} loading={generating} className={"primary-button"}>
                            {checkout_mode ? 'ACTIVATE COUPON' : 'GENERATE COUPON'}
                        </Button>
                    </div>

                    {generatedCoupon && !checkout_mode && (
                        <div className="pt-1">
                            <div className={"flex flex-col bg-purple-300 w-[350px] rounded-lg mt-2 mb-2"}>
                                <CouponComponent coupon={generatedCoupon} header_color={"bg-blue-200"} paste={paste} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>


    );


};

export default CouponGeneratePresetComponent;
