import React, { useEffect, useState } from 'react';
import { Checkbox, Form, Input, Button, notification, DatePicker } from "antd";
import Employees from "../../../../../http/Employees";
import FormContainer from "../../../../../components/FormContainer";
import FormItem from "../../../../../components/Form/FormItem";
import moment from "moment";
import {faCheckCircle, faWarning} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const EmployeeInformation = ({ employee, reload, discard, onEmployeeCreated }) => {
    const [form] = Form.useForm();
    const [validationFields, setValidationFields] = useState(null);
    const [saving, setSaving] = useState(false);
    const [isEdit, setIsEdit] = useState(!!employee);
    const [sendingPasswordReset, setSendingPasswordReset] = useState(false);
    const [sendingInvitation, setSendingInvitation] = useState(false);
    const [sendingVerification, setSendingVerification] = useState(false);

    useEffect(() => {
        if (employee) {
            setIsEdit(true);
            form.setFieldsValue({
                ...employee,
                birthday: employee.birthday ? moment(employee.birthday) : null,
                status: employee.status === 1
            });
        } else {
            setIsEdit(false);
            form.resetFields();
        }
    }, [employee, form]);

    const onSubmit = () => {
        form.validateFields().then(values => {
            onSave({
                ...values,
                birthday: values.birthday ? values.birthday.format('YYYY-MM-DD') : null,
                status: values.status ? 1 : -1
            }, isEdit);
        }).catch(info => {
            console.log('Validate Failed:', info);
        });
    };

    const onSave = (values, isEdit) => {
        setSaving(true);
        if (isEdit) {
            Employees.UpdateInformation(employee.id, values, (response) => {
                reload();
                const user = response.data.data.employee;
                notification['success']({
                    message: 'Success!',
                    placement: "bottomRight",
                    description: `Employee ${user.email} has been updated!`,
                });
                setSaving(false);
                setValidationFields(null);
            }, (err) => {
                setValidationFields(err.response.data.errors);
                setSaving(false);
            });
        } else {
            Employees.Store(values, (response) => {
                const newEmployeeId = response.data.data.employee.id;
                reload();
                notification['success']({
                    message: 'Success!',
                    placement: "bottomRight",
                    description: `Employee ${response.data.data.employee.email} has been created!`,
                });
                setSaving(false);
                setValidationFields(null);
                form.resetFields();
                if (onEmployeeCreated) {
                    onEmployeeCreated(newEmployeeId);
                }
            }, (err) => {
                setValidationFields(err.response.data.errors);
                setSaving(false);
            });
        }
    };

    const sendPasswordReset = () => {
        setSendingPasswordReset(true);
        Employees.SendPasswordReset(employee.id, (response) => {
            notification['success']({
                message: 'Success!',
                placement: "bottomRight",
                description: response.data.message
            });
            setSendingPasswordReset(false);
        }, (err) => {
            notification['error']({
                message: 'Error!',
                placement: "bottomRight",
                description: err.response.data.message
            });
            setSendingPasswordReset(false);
        });
    };

    const sendInvitation = () => {
        setSendingInvitation(true);
        Employees.SendInvitation(employee.id, (response) => {
            notification['success']({
                message: 'Success!',
                placement: "bottomRight",
                description: response.data.message
            });
            setSendingInvitation(false);
        }, (err) => {
            notification['error']({
                message: 'Error!',
                placement: "bottomRight",
                description: err.response.data.message
            });
            setSendingInvitation(false);
        });
    };

    const sendVerification = () => {
        setSendingVerification(true);
        Employees.SendVerification(employee.id, (response) => {
            notification['success']({
                message: 'Success!',
                placement: "bottomRight",
                description: response.data.message
            });
            setSendingVerification(false);
        }, (err) => {
            notification['error']({
                message: 'Error!',
                placement: "bottomRight",
                description: err.response.data.message
            });
            setSendingVerification(false);
        });
    };

    const formatDate = (dateString) => {
        return moment(dateString).format('MMMM Do YYYY, h:mm:ss a');
    };

    return (
        <div>
            <Form
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                initialValues={{
                    status: true,
                }}
            >
                <FormContainer>
                    <FormItem
                        name="name"
                        label="Full Name"
                        rules={[{ required: true, message: 'Please input the full name!' }]}
                        errors={validationFields}
                        disabled={saving}
                    >
                        <Input />
                    </FormItem>

                    <FormItem
                        name="email"
                        label="Email"
                        rules={[{ required: true, message: 'Please input the email!' }]}
                        errors={validationFields}
                    >
                        <Input type="email" disabled={!!employee} />
                    </FormItem>

                    <FormItem
                        name="birthday"
                        label="Birthday"
                        errors={validationFields}
                        disabled={saving}
                    >
                        <DatePicker format="YYYY-MM-DD" />
                    </FormItem>
                </FormContainer>
                {employee && (
                    <>
                        <FormContainer>
                            <FormItem
                                name="password"
                                label="Password"
                                rules={[{ required: !isEdit, message: 'Please input the password!' }]}
                                errors={validationFields}
                                disabled={saving}
                            >
                                <Input.Password
                                    visibilityToggle={true}
                                    autoComplete="new-password"
                                />
                            </FormItem>

                            <div className="flex flex-row w-full justify-end">
                                <FormItem style={{ marginRight: "12px" }}>
                                    <Button type="primary" loading={sendingInvitation} onClick={sendInvitation}>
                                        Send Invitation
                                    </Button>
                                </FormItem>

                                <FormItem>
                                    <Button type="primary" loading={sendingPasswordReset} onClick={sendPasswordReset}>
                                        Send Password Reset
                                    </Button>
                                </FormItem>
                            </div>
                        </FormContainer>

                        <FormContainer>
                            <div>
                                {employee.email_verified_at ? (
                                    <div className="text-green-700 pb-3 flex flex-row items-center">
                                        <FontAwesomeIcon icon={faCheckCircle} className=" text-green-800 mr-2 text-lg" aria-hidden="true"/>
                                        Email has been verified at {formatDate(employee.email_verified_at)}
                                    </div>
                                ) : (
                                    <div className="font-bold text-red-800">
                                        <FontAwesomeIcon icon={faWarning} className=" text-red-800 mr-2 text-base" aria-hidden="true"/>
                                        Email is not verified.
                                    </div>
                                )}
                            </div>

                            {!employee.email_verified_at && (
                                <div className="flex flex-row w-full justify-end">
                                    <FormItem>
                                        <Button type="primary" loading={sendingVerification} onClick={sendVerification}>
                                            Resend Email Verification
                                        </Button>
                                    </FormItem>
                                </div>
                            )}
                        </FormContainer>

                        <FormContainer>
                            <FormItem
                                name="pin"
                                label="PIN"
                                errors={validationFields}
                                disabled={saving}
                            >
                                <Input.Password
                                    visibilityToggle={true}
                                />
                            </FormItem>
                            <FormItem name="status" label="Status" valuePropName="checked">
                                <Checkbox>Enabled</Checkbox>
                            </FormItem>
                        </FormContainer>
                    </>
                )}
                <div className="flex flex-row p-1">
                    <FormItem>
                        <Button type="primary" htmlType="submit" loading={saving} style={{ paddingLeft: "40px", paddingRight: "40px" }}>
                            {isEdit ? 'Save' : 'Invite'}
                        </Button>
                    </FormItem>

                    <FormItem style={{ marginLeft: "10px" }}>
                        <Button type="link" htmlType="button" disabled={saving} danger onClick={discard}>
                            Discard Changes
                        </Button>
                    </FormItem>
                </div>
            </Form>
        </div>
    );
};

export default EmployeeInformation;
