    import {useDispatch, useSelector} from "react-redux";
import SkeletonWrapper from "../../components/skeletons/SkeletonWrapper";
import MenuSkeleton from "../../components/skeletons/MenuSkeleton";
import React, {useEffect, useState} from "react";
import CartItem from "./CartItem";
import {calculatePrice, convertCartItemToFood} from "../../services/food";
import {Button, Modal, notification} from "antd";
import {checkout, CheckoutReset, CheckoutStoreReset} from "../../redux/checkout/actionCreator";
import {useHistory} from "react-router";
import {roundToTwo} from "../../services/global";
import {motion} from "framer-motion";
import {SetRedirectSource} from "../../redux/authentication/actionCreator";
import {closeStoreCartBubble, closeUpdateCartBubble, EmptyCart, removeFromCart} from "../../redux/cart/actionCreator";
import "../../config";
import T from "../../components/T";
import Settings from "../../http/Settings";
import {UpdateMenu} from "../../redux/menu/actionCreator";

export default function Cart() {
    const menu = useSelector(state => state.menu.index_menu);
    const loading = useSelector(state => state.menu.index_loading);
    const error = useSelector(state => state.menu.index_error);
    const cart_items = useSelector(state => state.cart.cart_items);
    const [disabled, setDisabled] = useState(false);

    const checkout_loading = useSelector(state => state.checkout.store_loading);
    const checkout_done = useSelector(state => state.checkout.store_done);
    const checkout_error = useSelector(state => state.checkout.store_error);
    const dispatch = useDispatch();

    const history = useHistory();

    let [totalPrice, setTotalPrice] = useState(0);
    let [taxRates] = useState(global.config.taxRates);
    let [tax, setTax] = useState(0);
    let [checkingStocks, setCheckingStocks] = useState(false);

    useEffect(() => {
        dispatch(CheckoutReset());
        SetRedirectSource(null);
    }, []);

    useEffect(() => {
        if (menu && cart_items) {
            let tp = 0;
            for (const key in cart_items) {
                const storageCartItem = cart_items[key];
                const selectedItems = storageCartItem[1];
                const quantity = storageCartItem[2];
                const food = convertCartItemToFood(menu, storageCartItem);
                if(food == null) {
                    dispatch(EmptyCart());
                    return;
                }
                const price = calculatePrice(food, selectedItems);
                tp += (price * quantity);
            }
            setTotalPrice(tp);

            let t = 0;
            taxRates.map(rate => t += Number((tp * rate).toFixed(2)))
            setTax(t);
        }
    }, [menu, cart_items])

    const handleCheckout = () => {
        setDisabled(true);
        dispatch(checkout());
    }

    useEffect(() => {
        if (checkout_error) {
            setDisabled(false);
            Modal.warning({
                title: 'Checkout Error',
                content: checkout_error,
                onOk() {
                    dispatch(CheckoutStoreReset());
                }
            });
        }
    }, [checkout_error, checkout_loading]);

    useEffect(() => {
        if (checkout_done === true) {
            history.push("/checkout");
        }
    }, [checkout_done, checkout_loading]);


    useEffect(() => {
        if(menu && cart_items) {
            setCheckingStocks(true);
            Settings.KioskVersion((response) => {


                const categories = response.data.data.categories;
                const foods = response.data.data.foods;
                const options = response.data.data.options;
                //console.log("UpdateMenu Ran!!");
                /*
                dispatch(UpdateMenu(categories, foods, options));
                */

                for (const key in cart_items) {
                    const storageCartItem = cart_items[key];
                    const food_id = storageCartItem[0];
                    const _key = storageCartItem[3];
                    const food = foods.find(food => food.id === food_id);
                    if(food.in_stock == 0) {
                        // we should remove this food from cart_items
                        notification['error']({
                            message: 'Out of Stock!',
                            description: food.name+" is out of stock!"
                        });

                        dispatch(closeStoreCartBubble());
                        dispatch(closeUpdateCartBubble());
                        dispatch(removeFromCart(cart_items, _key, false));
                    }
                }
                setCheckingStocks(false);
            }, (err) => {
                setCheckingStocks(false);
            });
        }
    }, [cart_items]);

    return (
        <SkeletonWrapper loading={loading} skeleton={MenuSkeleton}>
            {cart_items && cart_items.length > 0 ?
                <motion.div
                    initial={{opacity: 0, y: -200}}
                    animate={{opacity: 1, y: 0}}
                    style={{paddingBottom: "5vw"}}
                >
                    <div className="row mt-5 lg:mt-0">
                        <div className="md:container flex flex-col mx-auto px-3 py-0 lg:p-10">
                            <h1 className="text-4xl font-bold text-black uppercase"><T>MY BAG</T></h1>
                            <div className="row flex mt-2 flex-col lg:flex-row relative">
                                <div className="w-full lg:w-2/3 lg:pr-3 flex flex-col">

                                    {cart_items.map((item) => {
                                        return <CartItem storageCartItem={item} key={item[3]} disabled={disabled || checkout_loading}/>
                                    })}

                                </div>
                                <div className="w-full lg:w-1/3 sticky">
                                    <div className="rounded-2xl border shadow-xl bg-white p-4 lg:p-8 flex flex-col">
                                        <h1 className="text-2xl font-bold mb-3 mx-2 uppercase">{cart_items.reduce(function (sum, current) {
                                            return sum + current[2];
                                        }, 0)} <T>ITEMS</T></h1>
                                        <hr/>
                                        <div className="m-3 flex flex-col">
                                            <div className="row flex my-2">
                                                <span className="flex-1 capitalize"><T>Subtotal</T></span>
                                                <span>${roundToTwo(totalPrice)}</span>
                                            </div>
                                            <div className="row flex mb-2">
                                                <span className="flex-1 capitalize"><T>Tax</T></span>
                                                <span>${roundToTwo(tax)}</span>
                                            </div>
                                            <div className="row flex mb-2">
                                                <span className="flex-1 capitalize"><T>Estimated Total</T></span>
                                                <span>${roundToTwo(totalPrice + tax)}</span>
                                            </div>
                                        </div>
                                        <hr/>
                                        <Button
                                            type="primary"
                                            shape="round"
                                            size="large"
                                            className="hover:bg-red-700 text-white text-xl font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline mt-4 flex flex-row flex-1 items-center block"
                                            style={{display: "flex", backgroundColor: "black", border: "none"}}
                                            loading={disabled || checkout_loading}
                                            onClick={handleCheckout}
                                        >
                                            <div className="text-white hover:text-white flex-1 text-left capitalize"><T>Checkout</T></div>
                                            <div className="text-white hover:text-white">${roundToTwo(totalPrice + tax)}</div>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </motion.div>
                :
                <motion.div
                    initial={{opacity: 0, y: -200}}
                    animate={{opacity: 1, y: 0}}
                    style={{paddingBottom: "5vw"}}
                >
                    <div className="card flex items-center flex-col m-10 p-5 bg-white rounded-2xl">
                        <div className="card-body cart">
                            <div className="col-sm-12 empty-cart-cls text-center text-lg">
                                <img src="/images/cart-empty.png" className="mb-8 w-64"/>
                                <div className="text-2xl font-bold capitalize"><strong><T>Your Cart is Empty</T></strong></div>
                            </div>
                        </div>
                    </div>
                </motion.div>
            }
        </SkeletonWrapper>
    )
}