import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Table, Pagination, notification, Modal } from 'antd';
import { RiEditLine, RiDeleteBinLine, RiAddLine } from '@remixicon/react';
import Device from '../../../../http/Device';
import DefaultContainer from '../../../../components/DefaultContainer';

const { confirm } = Modal;

const DeviceListing = () => {
    const [devices, setDevices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

    useEffect(() => {
        fetchDevices(pagination.current, pagination.pageSize);
    }, [pagination.current, pagination.pageSize]);

    const fetchDevices = (page, perPage) => {
        setLoading(true);
        Device.Index(page, perPage, (response) => {
            setDevices(response.data.data);
            setLoading(false);
        }, (err) => {
            notification.error({
                message: 'Error',
                description: 'Failed to load devices.',
            });
            setLoading(false);
        });
    };

    const handleDelete = (id, name) => {
        confirm({
            title: `Do you want to delete the device "${name}"?`,
            content: 'This action is irreversible. Please confirm your decision.',
            onOk() {
                return new Promise((resolve) => {
                    Device.Destroy(id, () => {
                        fetchDevices(pagination.current, pagination.pageSize);
                        resolve();
                        notification.success({
                            message: 'Success',
                            description: `Device "${name}" deleted successfully.`,
                        });
                    }, (err) => {
                        notification.error({
                            message: 'Error',
                            description: err.response?.data.message || 'Failed to delete the device.',
                        });
                        resolve();
                    });
                });
            },
            onCancel() {},
        });
    };

    const columns = [
        {
            title: 'Branch',
            dataIndex: ['branch', 'address'],
            key: 'branch',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Actions',
            key: 'actions',
            width: 80, // Set a specific width to make it as small as possible
            render: (text, record) => (
                <div className="table-actions flex flex-row justify-end items-center">
                    <Button type="link" danger onClick={() => handleDelete(record.id, record.name)} className="p-0" style={{margin: "0px 10px 0 0", padding: "0 0"}}>
                        <RiDeleteBinLine />
                    </Button>
                    <Link to={`/dashboard/terminals/devices/${record.id}/edit`} className="btn-icon pr-2">
                        <RiEditLine />
                    </Link>
                </div>
            ),
        },
    ];

    const handleTableChange = (current, pageSize) => {
        setPagination({ current, pageSize });
    };

    return (
        <DefaultContainer className={"mx-auto max-w-8xl py-2 mt-4"} append>
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold">Devices</h2>
                <Link to="/dashboard/terminals/devices/create">
                    <Button type="primary" icon={<RiAddLine className={"mr-2"} />} className={"primary-button"}>
                        Add Device
                    </Button>
                </Link>
            </div>
            <Table
                columns={columns}
                dataSource={devices.data}
                pagination={false}
                rowKey="id"
                loading={loading}
            />
            <div className={"mt-4"}>
                <Pagination
                    total={devices.total}
                    showSizeChanger
                    showTotal={(total) => `Total ${total} items`}
                    current={pagination.current}
                    pageSize={pagination.pageSize}
                    onChange={handleTableChange}
                />
            </div>
        </DefaultContainer>
    );
};

export default DeviceListing;
