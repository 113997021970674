import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from "react-router-dom";
import { Tabs } from 'antd';
import Employees from "../../../../http/Employees";
import GenericSkeleton from "../../../../components/skeletons/GenericSkeleton";
import SkeletonWrapper from "../../../../components/skeletons/SkeletonWrapper";
import T from "../../../../components/T";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward, faContactCard } from "@fortawesome/free-solid-svg-icons";
import EmployeeInformation from "./Edit/EmployeeInformation";
import EmployeeDepartments from "./Edit/EmployeeDepartments";
import EmployeePermissions from "./Edit/EmployeePermissions";

const EditEmployee = () => {
    const { id } = useParams();
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [employee, setEmployee] = useState(null);
    const [departments, setDepartments] = useState(null);

    useEffect(() => {
        if (id) {
            reload();
        }
    }, [id]);

    const discard = () => {
        history.push("/dashboard/employees/manage");
    }

    const reload = () => {
        if (!employee)
            setLoading(true);
        Employees.Edit(id, (response) => {
            setLoading(false);
            setEmployee(response.data.data.employee);
            setDepartments(response.data.data.departments);
        }, (err) => {
            setLoading(false);
        });
    };

    const handleEmployeeCreated = (newEmployeeId) => {
        history.push(`/dashboard/employees/${newEmployeeId}/edit`);
    };

    return (
        <div className="w-full mx-auto max-w-6xl">
            <div className="text-xl font-bold text-black md:pl-1 flex flex-row mb-2 justify-between flex flex-col md:flex-row">
                <div className={"flex flex-row justify-between w-full items-center"}>
                    <T upper>Edit Employee</T>
                    <Link to={"/dashboard/employees/manage"} className={"lowercase text-black text-sm"}>
                        <FontAwesomeIcon icon={faBackward} className=" mr-1 text-sm cursor-pointer" aria-hidden="true" /> back to employees
                    </Link>
                </div>
            </div>
            <div className="rounded-lg flex-1 border shadow-xl bg-white p-3 flex flex-col items-center justify-center pt-3 pb-2 w-full" style={{backgroundColor:"#FAFAFA"}}>
                <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                    {(employee || !id) && (
                        <div className="flex flex-col w-full">
                            <div className={"text-xl pt-1 pb-3 text-black font-bold border-b border-gray-300 mb-2 flex flex-row items-center"}>
                                <FontAwesomeIcon icon={faContactCard} className=" mr-2 text-xl cursor-pointer" aria-hidden="true" />
                                {employee ? employee.name : "New Employee"}
                            </div>
                            <div className={"employee-form flex flex-row w-full"} >
                                <Tabs
                                    tabPosition="left"
                                    defaultActiveKey="1"
                                    className="w-full"
                                >
                                    <Tabs.TabPane tab="INFORMATION" key="1">
                                        <EmployeeInformation
                                            employee={employee}
                                            reload={reload}
                                            discard={discard}
                                            onEmployeeCreated={handleEmployeeCreated}
                                        />
                                    </Tabs.TabPane>

                                    {id &&
                                        <Tabs.TabPane tab="DEPARTMENTS" key="3">
                                            <EmployeeDepartments employee={employee} departments={departments} reload={reload} discard={discard}/>
                                        </Tabs.TabPane>
                                    }

                                    {id &&
                                        <Tabs.TabPane tab="PERMISSIONS" key="2">
                                            <EmployeePermissions employee={employee} reload={reload} discard={discard}/>
                                        </Tabs.TabPane>
                                    }
                                </Tabs>
                            </div>
                        </div>
                    )}
                </SkeletonWrapper>
            </div>
        </div>
    );
};

export default EditEmployee;
