import axios from 'axios';
import {checkLoggedIn, getAuthenticationHeaders} from './Headers';

const Settings = {

    GetSettings: (names, successCb, failCb) => {
        axios.post('/bloggers/settings/fetch', {names}, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    SaveSettings: (settings, successCb, failCb) => {
        axios.post('/bloggers/settings/save', {settings}, getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            checkLoggedIn(err);
            failCb(err);
        });
    },

    Version: (successCb, failCb) => {
        axios.get('/version', getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },

    KioskVersion: (successCb, failCb) => {
        axios.get('/kiosk_version', getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },
};

export default Settings;
