import actions from './actions';

const {
  MENU_INDEX_BEGIN,
  MENU_INDEX_SUCCESS,
  MENU_INDEX_ERR,
  UPDATE_MENU,

} = actions;

const initState = {
  index_loading: false,
  index_menu: null,
  index_options: null,
  index_images: null,
  index_error: null,
  index_categories: null,
  index_translations: null,
  public_path: null,
};

const MenuReducer = (state = initState, action) => {
  const { type, menu, options, images, translations, error, categories, public_path } = action;
  switch (type) {

    case MENU_INDEX_BEGIN:
      return {
        ...state,
        index_loading: true,
        index_error: null,
        index_menu: null,
        index_categories: null,
        index_options: null,
      };

    case MENU_INDEX_SUCCESS:
      let foods = [];
      menu.map(category => {
        category.foods.map(food => {
          foods.push(food);
        });
      })
      return {
        ...state,
        index_loading: false,
        index_menu: foods,
        index_images: images,
        index_categories: menu,
        index_translations: translations,
        public_path: public_path,
        index_options: options,

      };

    case MENU_INDEX_ERR:
      return {
        ...state,
        index_loading: false,
        index_error: error,
      };


    case UPDATE_MENU:

      //console.log("inside UPDATE_MENU");
      if(!state.index_options)
        return state;

      //console.log("inside UPDATE_MENU after if");

      let anyOptionUpdated = false;
      const updatedOptions = state.index_options.map(option => {
        const updatedOption = action.options.find(f => f.id === option.id);

        let optionUpdated = false;

        if (updatedOption && updatedOption.in_stock !== option.in_stock) {
          option.in_stock = updatedOption.in_stock; optionUpdated = true;
        }

        if(optionUpdated) {
          anyOptionUpdated = true;
          return {...option};
        }
        else
          return option;
      });


      let anyFoodUpdated = false;
      const updatedMenu = state.index_menu.map(food => {
        const updatedFood = action.foods.find(f => f.id === food.id);

        let foodUpdated = false;

        if (updatedFood && updatedFood.status !== food.status) {food.status = updatedFood.status; foodUpdated = true; }
        if (updatedFood && updatedFood.in_stock !== food.in_stock) {
          food.in_stock = updatedFood.in_stock; foodUpdated = true;
        }

        if(foodUpdated) {
          anyFoodUpdated = true;
          return {...food};
        }
        else
          return food;
      });


      const updatedCategories = state.index_categories.map(category => {
        const updatedCategory = categories.find(c => c.id === category.id);

        let categoryUpdated = false;
        if (updatedCategory) {
          // Update category status if it has changed
          if (updatedCategory.status !== category.status) {
            categoryUpdated = true;
            category = { ...category, status: updatedCategory.status };
          }

          // Update food statuses within the category
          const updatedFoods = category.foods.map(food => {

            let foodUpdated = false;

            const updatedFood = action.foods.find(f => f.id === food.id);

            if (updatedFood && updatedFood.status !== food.status) {food.status = updatedFood.status; foodUpdated = true; categoryUpdated = true; categoryUpdated = true;}
            if (updatedFood && updatedFood.in_stock !== food.in_stock) {food.in_stock = updatedFood.in_stock; foodUpdated = true; categoryUpdated = true; categoryUpdated = true;}

            if(foodUpdated) {
              return {...food};
            }
            else
              return food;
          });

          if(categoryUpdated) {
            category = {...category, foods: updatedFoods};
          }
        }
        return category;
      });

      if(anyFoodUpdated) {
        //console.log("foods updated!");
      }
      //console.log("updatedCategories", updatedCategories);
      //console.log("index_menu", anyFoodUpdated?updatedMenu:state.index_menu);
      //console.log("index_options", anyOptionUpdated?updatedOptions:state.index_options);

      return {
        ...state,
        index_menu: anyFoodUpdated?updatedMenu:state.index_menu,
        index_options: anyOptionUpdated?updatedOptions:state.index_options
      };


    default:
      return state;
  }
};
export default MenuReducer;
