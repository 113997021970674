import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Form, Input, Button, Select, notification} from 'antd';
import InventoryVendor from "../../../../../http/InventoryVendor";
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../../components/skeletons/GenericSkeleton";
import DefaultContainer from "../../../../../components/DefaultContainer";

const {Option} = Select;

const CreateVendor = () => {
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [vendors, setVendors] = useState([]);
    const history = useHistory();
    const {id} = useParams();
    const [form] = Form.useForm();

    useEffect(() => {
        if (id) {
            InventoryVendor.Edit(id, (response) => {
                const vendor = response.data.data.vendor;
                setVendors(response.data.data.vendors);
                form.setFieldsValue(vendor);
                setLoading(false);
            }, (err) => {
                setLoading(false);
                notification['error']({
                    message: 'Error!',
                    description: 'Failed to load vendor',
                });
                history.push("/dashboard/inventory/vendors/manage");
            });
        } else {
            InventoryVendor.Create(id, (response) => {
                const vendor = response.data.data.vendor;
                setVendors(response.data.data.vendors);
                form.setFieldsValue(vendor);
                setLoading(false);
            }, (err) => {
                setLoading(false);
                notification['error']({
                    message: 'Error!',
                    description: 'Failed to load vendor',
                });
                history.push("/dashboard/inventory/vendors/manage");
            });
        }
    }, [id]);

    const onFinish = (values) => {
        setSubmitting(true);
        if (id) {
            InventoryVendor.Update(id, values, (response) => {
                setSubmitting(false);
                history.push("/dashboard/inventory/vendors/manage");
                notification['success']({
                    message: 'Success!',
                    description: response.data.message,
                });
            }, (err) => {
                setSubmitting(false);
                notification['error']({
                    message: 'Error!',
                    description: err.response?.data.message,
                });
            });
        } else {
            InventoryVendor.Store(values, (response) => {
                setSubmitting(false);
                history.push("/dashboard/inventory/vendors/manage");
                notification['success']({
                    message: 'Success!',
                    description: response.data.message,
                });
            }, (err) => {
                setSubmitting(false);
                notification['error']({
                    message: 'Error!',
                    description: err.response?.data.message,
                });
            });
        }
    };

    return (
        <DefaultContainer className="mx-auto max-w-8xl" append>
            <div className="flex flex-col pt-3 w-full" style={{backgroundColor: "#FAFAFA"}}>
                <h1 className="text-2xl font-bold mb-4 uppercase patty upper border-b border-gray-200">{id ? 'Edit Vendor' : 'Add New Vendor'}</h1>
                <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                    <Form form={form} layout="vertical" onFinish={onFinish} disabled={submitting || loading}>
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[{required: true, message: 'Please input the vendor name'}]}
                        >
                            <Input placeholder="Enter vendor name" disabled={submitting}/>
                        </Form.Item>
                        <Form.Item
                            label="Type"
                            name="type"
                            rules={[{required: true, message: 'Please select the vendor type'}]}
                        >
                            <Select placeholder="Select vendor type" disabled={submitting}>
                                <Option value="central_kitchen">Central Kitchen</Option>
                                <Option value="vendor">Vendor</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Assign Vendor User"
                            name="vendor_user_id"
                            rules={[{required: true, message: 'Please select a user to assign'}]}
                        >
                            <Select placeholder="Select user to assign" disabled={submitting}>
                                {vendors.map((vendor) => (
                                    <Option key={vendor.id} value={vendor.id}>
                                        {vendor.name} ({vendor.email})
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={submitting} className="w-full primary-button">
                                {id ? 'Update Vendor' : 'Add Vendor'}
                            </Button>
                        </Form.Item>
                    </Form>
                </SkeletonWrapper>
            </div>
        </DefaultContainer>
    );
};

export default CreateVendor;
