import React, {useState, useEffect} from 'react';
import {Checkbox, Button, message} from 'antd';
import Roles from '../../../../http/Roles';
import DefaultContainer from "../../../../components/DefaultContainer";
import SkeletonWrapper from "../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../components/skeletons/GenericSkeleton";
import {useParams} from 'react-router-dom';

const EditRoleAbilities = () => {
    const {roleId} = useParams();
    const [abilitiesByGroup, setAbilitiesByGroup] = useState({});
    const [roleName, setRoleName] = useState('');
    const [loading, setLoading] = useState(false);
    const [editLoading, setEditLoading] = useState(false);

    useEffect(() => {
        fetchRoleAbilities();
    }, [roleId]);

    const fetchRoleAbilities = () => {
        setEditLoading(true);
        Roles.GetAbilities(roleId, (response) => {
            setAbilitiesByGroup(response.data.data.abilities_by_group);
            setRoleName(response.data.data.role.label);
            setEditLoading(false);
        }, (err) => {
            message.error('Failed to load role abilities');
            setEditLoading(false);
        });
    };

    const handleAbilityToggle = (groupId, abilityId) => {
        setAbilitiesByGroup(prevGroups => {
            const newGroups = {...prevGroups};
            const abilities = newGroups[groupId].abilities;
            const ability = abilities.find(a => a.id === abilityId);
            ability.checked = !ability.checked;
            return newGroups;
        });
    };

    const handleGroupToggle = (groupId) => {
        setAbilitiesByGroup(prevGroups => {
            const newGroups = {...prevGroups};
            const abilities = newGroups[groupId].abilities;
            const allChecked = abilities.every(a => a.checked);
            newGroups[groupId].abilities = abilities.map(a => ({...a, checked: !allChecked}));
            return newGroups;
        });
    };

    const saveRoleAbilities = () => {
        setLoading(true);
        const selectedAbilities = Object.values(abilitiesByGroup)
            .flatMap(group => group.abilities)
            .filter(a => a.checked)
            .map(a => a.id);

        Roles.UpdateAbilities(roleId, selectedAbilities, (response) => {
            message.success('Role abilities updated');
            setLoading(false);
        }, (err) => {
            message.error('Failed to update role abilities');
            setLoading(false);
        });
    };

    return (
        <DefaultContainer className="edit-role-abilities mx-auto max-w-6xl">
            <div className="text-xl font-bold text-black mb-2 py-2">
                Manage Abilities for Role: <span className="text-primary">{roleName}</span>
            </div>
            <SkeletonWrapper loading={editLoading} skeleton={GenericSkeleton}>
                <div className="role-abilities">
                    {Object.keys(abilitiesByGroup).map(groupId => (
                        <div
                            key={groupId}
                            className="mb-4 p-2 border border-gray-300 rounded bg-white shadow-md box"
                        >
                            <div className="flex items-center text-black mb-2">
                                <Checkbox
                                    onChange={() => handleGroupToggle(groupId)}
                                    checked={abilitiesByGroup[groupId].abilities.every(a => a.checked)}
                                    indeterminate={abilitiesByGroup[groupId].abilities.some(a => a.checked) && !abilitiesByGroup[groupId].abilities.every(a => a.checked)}
                                    style={{marginRight: '8px'}}
                                    className={"flex flex-row items-center font-bold"}
                                >
                                    {abilitiesByGroup[groupId].group_name || 'Unknown Group'}
                                </Checkbox>
                            </div>
                            <div className="flex flex-wrap">
                                {abilitiesByGroup[groupId].abilities.map(ability => (
                                    <div key={ability.id} className="px-1 m-1 bg-gray-100 border">
                                        <Checkbox
                                            onChange={() => handleAbilityToggle(groupId, ability.id)}
                                            checked={ability.checked}
                                        >
                                            {ability.label}
                                        </Checkbox>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="flex items-center justify-end mt-4">
                    <Button
                        type="primary"
                        onClick={saveRoleAbilities}
                        loading={loading}
                        disabled={loading}
                    >
                        Save Changes
                    </Button>
                </div>
            </SkeletonWrapper>
        </DefaultContainer>
    );
};

export default EditRoleAbilities;
