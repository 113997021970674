import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Button, Table, Popconfirm, notification} from 'antd';
import InventoryVendor from '../../../../../http/InventoryVendor';
import SkeletonWrapper from '../../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../../components/skeletons/GenericSkeleton';
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import DefaultContainer from "../../../../../components/DefaultContainer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";

const ManageVendors = () => {
    const [loading, setLoading] = useState(true);
    const [vendors, setVendors] = useState([]);
    const history = useHistory();

    useEffect(() => {
        reload();
    }, []);

    const reload = () => {
        setLoading(true);
        InventoryVendor.Index((response) => {
            setVendors(response.data.data.vendors);
            setLoading(false);
        }, (err) => {
            setLoading(false);
        });
    };

    const handleDelete = (id) => {
        InventoryVendor.Delete(id, (response) => {
            notification['success']({
                message: 'Success!',
                description: 'Vendor deleted successfully',
            });
            reload();
        }, (err) => {
            notification['error']({
                message: 'Error!',
                description: 'Failed to delete vendor',
            });
        });
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'User',
            dataIndex: ['user', 'email'],
            key: 'user',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <div>
                    <Link to={`/dashboard/inventory/vendors/${record.id}/edit`}>
                        <Button icon={<EditOutlined/>}/>
                    </Link>
                    <Popconfirm
                        title="Are you sure delete this vendor?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button icon={<DeleteOutlined/>} className="ml-2"/>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    const onVendorCreate = () => {
        history.push("/dashboard/inventory/vendors/new");
    }

    return (
        <DefaultContainer className="mx-auto max-w-8xl" append>
            <div className="flex flex-col pt-3 w-full">
                <div className="text-xl font-bold text-black md:pl-1 flex flex-row mb-2 justify-between flex flex-col md:flex-row w-full">
                    <div className={"flex flex-row items-center justify-between w-full"}>
                        <span className={""}>Manage Vendors</span>
                        <Button onClick={onVendorCreate} style={{borderRadius: "5px"}} type={"primary"} className={"primary-button"}>
                            <div className={"flex flex-row items-center"}>
                                <FontAwesomeIcon icon={faPlusCircle} className="mr-2 text-lg cursor-pointer" aria-hidden="true"/>
                                Add New Vendor
                            </div>
                        </Button>
                    </div>
                </div>

                <div className="mb-4 pl-1 text-gray-600">
                    <p>
                        This section helps you manage your suppliers, ensuring a reliable source for all your inventory needs.
                    </p>
                </div>

                <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                    <Table dataSource={vendors} columns={columns} rowKey="id"/>
                </SkeletonWrapper>
            </div>
        </DefaultContainer>
    );
};

export default ManageVendors;
