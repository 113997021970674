import React, { useEffect, useState } from 'react';
import { Button, Table, notification, Popconfirm, Select } from 'antd';
import { Link } from 'react-router-dom';
import { EditOutlined, DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import BranchInventoryGroupContainer from '../../../../../http/BranchInventoryGroupContainer';
import SkeletonWrapper from '../../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../../components/skeletons/GenericSkeleton';
import DefaultContainer from '../../../../../components/DefaultContainer';
import BranchInventoryGroup from '../../../../../http/BranchInventoryGroup';

const { Option } = Select;

const ManageBranchInventoryGroupContainers = () => {
    const [loading, setLoading] = useState(true);
    const [inventoryContainers, setInventoryContainers] = useState([]);
    const [filteredContainers, setFilteredContainers] = useState([]);
    const [branches, setBranches] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState('all');

    useEffect(() => {
        fetchBranches();
        fetchInventoryGroupContainers();
    }, []);

    const fetchBranches = () => {
        BranchInventoryGroupContainer.Create((response) => {
            const { branches } = response.data.data;
            setBranches(branches);
        }, (err) => {
            setBranches([]);
            notification['error']({
                message: 'Error!',
                description: 'Failed to load branches',
            });
        });
    };

    const fetchInventoryGroupContainers = () => {
        setLoading(true);
        BranchInventoryGroupContainer.Index((response) => {
            setInventoryContainers(response.data.data.branch_inventory_group_containers);
            setFilteredContainers(response.data.data.branch_inventory_group_containers);
            setLoading(false);
        }, (err) => {
            setLoading(false);
            notification['error']({
                message: 'Error!',
                description: 'Failed to load inventory group containers',
            });
        });
    };

    const handleDelete = (id) => {
        setLoading(true);
        BranchInventoryGroupContainer.Delete(id, (response) => {
            notification['success']({
                message: 'Success!',
                description: response.data.message,
            });
            fetchInventoryGroupContainers();
        }, (err) => {
            setLoading(false);
            notification['error']({
                message: 'Error!',
                description: 'Failed to delete inventory group container',
            });
        });
    };

    const handleBranchChange = (value) => {
        setSelectedBranch(value);
        if (value === 'all') {
            setFilteredContainers(inventoryContainers);
        } else {
            setFilteredContainers(inventoryContainers.filter(container => container.branch_inventory_group.branch.id === value));
        }
    };

    const columns = [
        {
            title: 'Branch',
            dataIndex: ['branch_inventory_group', 'branch', 'name'],
            key: 'branch',
        },
        {
            title: 'Inventory Group',
            dataIndex: ['branch_inventory_group', 'name'],
            key: 'branch_inventory_group',
        },
        {
            title: 'Container',
            dataIndex: ['container', 'name'],
            key: 'container',
        },
        {
            title: 'Full Container Weight (gr)',
            dataIndex: 'full_container_weight',
            key: 'full_container_weight',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <span>
                    <Link to={`/dashboard/inventory/branch_inventory_group_containers/${record.id}/edit`}>
                        <Button icon={<EditOutlined />} />
                    </Link>
                    <Popconfirm
                        title="Are you sure to delete this container?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button icon={<DeleteOutlined />} className="ml-2" />
                    </Popconfirm>
                </span>
            ),
        },
    ];

    return (
        <DefaultContainer className="mx-auto max-w-8xl" append>
            <div className="flex flex-col pt-3 w-full">
                <div className="flex flex-row justify-between items-start mb-2">
                    <div>
                        <h1 className="text-xl font-bold ">Manage Branch Inventory Group Containers</h1>
                        <div className="mb-4 text-gray-600">
                            This section allows you to specify which inventories are stored in which containers, enabling efficient organization and tracking of multiple storage options for each inventory item.
                        </div>
                    </div>
                    <Link to="/dashboard/inventory/branch_inventory_group_containers/new">
                        <Button type="primary" icon={<PlusCircleOutlined />} className={"primary-button"}>
                            Add Container
                        </Button>
                    </Link>
                </div>
                <div className="mb-4">
                    <Select placeholder="Filter by Branch" onChange={handleBranchChange} className="w-full">
                        <Option value="all">All Branches</Option>
                        {branches.map(branch => (
                            <Option key={branch.id} value={branch.id}>{branch.name}</Option>
                        ))}
                    </Select>
                </div>
                <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                    <Table dataSource={filteredContainers} columns={columns} rowKey="id" />
                </SkeletonWrapper>
            </div>
        </DefaultContainer>
    );
};

export default ManageBranchInventoryGroupContainers;
