import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Input, Button, Select, InputNumber, notification } from 'antd';
import Inventory from "../../../../../http/Inventory";
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../../components/skeletons/GenericSkeleton";
import DefaultContainer from "../../../../../components/DefaultContainer";

const { Option } = Select;

const CreateInventory = () => {
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [entityType, setEntityType] = useState('');
    const [entities, setEntities] = useState([]);
    const history = useHistory();
    const { id } = useParams();
    const [form] = Form.useForm();

    useEffect(() => {
        // Fetch foods and item types together when the component mounts
        Inventory.FetchEntities((response) => {
            const { foods, item_types } = response.data.data;
            setEntities([...foods, ...item_types]);
            if (id) {
                fetchInventory(id);
            } else {
                setLoading(false);
            }
        }, (err) => {
            setEntities([]);
            setLoading(false);
        });
    }, [id]);

    const fetchInventory = (id) => {
        Inventory.Edit(id, (response) => {
            const inventory = response.data.data.inventory;
            form.setFieldsValue(inventory);
            setEntityType(inventory.entity_type);
            setLoading(false);
        }, (err) => {
            setLoading(false);
            notification['error']({
                message: 'Error!',
                description: 'Failed to load inventory',
            });
            history.push("/dashboard/inventory/manage");
        });
    };

    const onFinish = (values) => {
        setSubmitting(true);
        if (id) {
            Inventory.Update(id, values, (response) => {
                setSubmitting(false);
                history.push("/dashboard/inventory/manage");
                notification['success']({
                    message: 'Success!',
                    description: response.data.message,
                });
            }, (err) => {
                setSubmitting(false);
                notification['error']({
                    message: 'Error!',
                    description: err.response?.data.message,
                });
            });
        } else {
            Inventory.Store(values, (response) => {
                setSubmitting(false);
                history.push("/dashboard/inventory/manage");
                notification['success']({
                    message: 'Success!',
                    description: response.data.message,
                });
            }, (err) => {
                setSubmitting(false);
                notification['error']({
                    message: 'Error!',
                    description: err.response?.data.message,
                });
            });
        }
    };

    const handleEntityTypeChange = (value) => {
        setEntityType(value);
        // Reset entity_id when entity_type changes
        form.setFieldsValue({ entity_id: undefined });
    };

    return (
        <DefaultContainer className="mx-auto max-w-8xl" append>
            <div className="flex flex-col pt-3 w-full" >
                <h1 className="text-2xl font-bold mb-4 uppercase patty upper border-b border-gray-200">{id ? 'Edit Inventory' : 'Add New Inventory'}</h1>
                <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                    <Form form={form} layout="vertical" onFinish={onFinish} disabled={submitting || loading}>
                        <Form.Item
                            label="Entity Type"
                            name="entity_type"
                            rules={[{ required: true, message: 'Please select the entity type' }]}
                        >
                            <Select placeholder="Select entity type" onChange={handleEntityTypeChange} disabled={submitting}>
                                <Option value="food">Food</Option>
                                <Option value="item_type">Item Type</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Entity"
                            name="entity_id"
                            rules={[{ required: true, message: 'Please select the entity' }]}
                        >
                            <Select
                                placeholder="Select entity"
                                disabled={!entityType || submitting}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {entities
                                    .filter(entity => entityType === 'food' ? entity.type === 'food' : entity.type === 'item_type')
                                    .map(entity => (
                                        <Option key={entity.id} value={entity.id}>
                                            {entity.name}
                                        </Option>
                                    ))}
                            </Select>

                        </Form.Item>
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: 'Please input the name' }]}
                        >
                            <Input placeholder="Enter name" disabled={submitting} />
                        </Form.Item>
                        <Form.Item
                            label="Unit Size"
                            name="unit_size"
                            rules={[{ required: true, message: 'Please input the unit size' }]}
                        >
                            <InputNumber min={0} step={0.01} className="w-full" placeholder="Enter unit size" disabled={submitting} />
                        </Form.Item>
                        <Form.Item
                            label="Unit Type"
                            name="unit_type"
                            rules={[{ required: true, message: 'Please select the unit type' }]}
                        >
                            <Select placeholder="Select unit type" disabled={submitting}>
                                <Option value="pc">pc</Option>
                                <Option value="gr">gr</Option>
                                <Option value="kg">kg</Option>
                                <Option value="ml">ml</Option>
                                <Option value="l">l</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Waste Percentage"
                            name="waste_percentage"
                            rules={[{ required: true, message: 'Please input the waste percentage' }]}
                        >
                            <InputNumber min={0} max={100} step={0.01} className="w-full" placeholder="Enter waste percentage" disabled={submitting} />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={submitting} className="w-full primary-button">
                                {id ? 'Update Inventory' : 'Add Inventory'}
                            </Button>
                        </Form.Item>
                    </Form>
                </SkeletonWrapper>
            </div>
        </DefaultContainer>
    );
};

export default CreateInventory;
