import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

const FoodSkeleton = () => {

    return (
        <div className="row">
            <div className="container flex flex-col mx-auto p-10">
                <div className="w-full">
                    <div className="bg-white ">
                        <Skeleton height={10} count={20} className="bg-black" />
                    </div>
                </div>
            </div>
        </div>
    )

};

export default FoodSkeleton;
