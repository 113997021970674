import React, { useState, useEffect } from 'react';
import {List, Button, message, Input, Popconfirm, Modal} from 'antd';
import { CopyOutlined, EditOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import Roles from '../../../../http/Roles';
import { useHistory } from 'react-router-dom';
import DefaultContainer from "../../../../components/DefaultContainer";

const RoleList = () => {
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editingRoleId, setEditingRoleId] = useState(null);
    const [roleLabel, setRoleLabel] = useState('');
    const [roleSlug, setRoleSlug] = useState('');
    const [duplicatingRole, setDuplicatingRole] = useState(false);
    const [selectedRole, setSelectedRole] = useState(null);

    const history = useHistory();

    useEffect(() => {
        fetchRoles();
    }, []);

    const fetchRoles = () => {
        setLoading(true);
        Roles.Index((response) => {
            setRoles(response.data.data.roles);
            setLoading(false);
        }, (err) => {
            message.error('Failed to load roles');
            setLoading(false);
        });
    };

    const handleDuplicateRole = (role) => {
        setSelectedRole(role);
        setRoleLabel(`${role.label} Copy`);
        setDuplicatingRole(true);
    };

    const handleDuplicateRoleSave = () => {
        setLoading(true);
        Roles.Duplicate(selectedRole.id, roleLabel, (response) => {
            message.success('Role duplicated');
            fetchRoles(); // Update the role list with the new role
            setDuplicatingRole(false);
            setLoading(false);
        }, (err) => {
            message.error('Failed to duplicate role');
            setLoading(false);
        });
    };

    const handleEditRole = (role) => {
        setEditingRoleId(role.id);
        setRoleLabel(role.label);
        setRoleSlug(role.name);
    };

    const handleSaveEdit = (roleId) => {
        setLoading(true);
        Roles.UpdateRole(roleId, { label: roleLabel }, (response) => {
            message.success('Role label updated');
            fetchRoles(); // Refresh the list with the updated label
            setEditingRoleId(null);
            setLoading(false);
        }, (err) => {
            message.error('Failed to update role label');
            setLoading(false);
        });
    };

    const handleCancelEdit = () => {
        setEditingRoleId(null);
    };

    const handleLabelChange = (e) => {
        setRoleLabel(e.target.value);
    };

    const handleManageAbilities = (role) => {
        history.push(`/dashboard/employees/roles/${role.id}/edit`);
    };

    return (
        <DefaultContainer className="mx-auto max-w-8xl">
            <div className="text-xl font-bold text-black mb-4 py-2">Manage Roles</div>
            <List
                bordered
                className={"box"}
                loading={loading}
                dataSource={roles}
                renderItem={role => (
                    <List.Item
                        actions={[
                            <Button type="link" onClick={() => handleManageAbilities(role)}>Manage Abilities</Button>,
                            <Button type="link" icon={<CopyOutlined />} onClick={() => handleDuplicateRole(role)}>Duplicate</Button>,
                        ]}
                    >
                        {editingRoleId === role.id ? (
                            <div className="flex items-center">
                                <Input
                                    value={roleLabel}
                                    onChange={handleLabelChange}
                                    placeholder="Enter role label"
                                    style={{ width: '200px', marginRight: '10px' }}
                                />
                                <Button
                                    type="primary"
                                    icon={<CheckOutlined />}
                                    onClick={() => handleSaveEdit(role.id)}
                                    loading={loading}
                                    style={{ marginRight: '5px' }}
                                />
                                <Button
                                    type="default"
                                    icon={<CloseOutlined />}
                                    onClick={handleCancelEdit}
                                    disabled={loading}
                                />
                            </div>
                        ) : (
                            <span>
                                {role.label} <strong className="text-xs pl-1">({role.name})</strong>
                                <Button
                                    type="link"
                                    icon={<EditOutlined />}
                                    onClick={() => handleEditRole(role)}
                                    style={{ marginLeft: '10px' }}
                                />
                            </span>
                        )}
                    </List.Item>
                )}
            />

            <Modal
                title="Duplicate Role"
                visible={duplicatingRole}
                onCancel={() => setDuplicatingRole(false)}
                onOk={handleDuplicateRoleSave}
                confirmLoading={loading}
            >
                <Input
                    value={roleLabel}
                    onChange={handleLabelChange}
                    placeholder="Enter new role label"
                />
            </Modal>
        </DefaultContainer>
    );
};

export default RoleList;
