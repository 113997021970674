import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Button, Input, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import AbilityGroup from './AbilityGroup';
import Abilities from '../../../../http/Abilities';
import DefaultContainer from "../../../../components/DefaultContainer";

const ManageAbilities = () => {
    const [abilityGroups, setAbilityGroups] = useState([]);
    const [newGroupName, setNewGroupName] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchAbilityGroups();
    }, []);

    const fetchAbilityGroups = () => {
        setLoading(true);
        Abilities.Index((response) => {
            setAbilityGroups(response.data.data.ability_groups);
            setLoading(false);
        }, (err) => {
            message.error('Failed to load ability groups');
            setLoading(false);
        });
    };

    const addNewGroup = () => {
        if (!newGroupName.trim()) return;

        setLoading(true);
        const newGroup = {
            name: newGroupName,
        };

        Abilities.StoreGroup(newGroup, (response) => {
            message.success('Ability group added');
            const newGroupWithAbilities = {
                ...response.data.data.ability_group,
                abilities: [] // Ensure abilities is initialized as an empty array
            };
            setAbilityGroups([...abilityGroups, newGroupWithAbilities]);
            setNewGroupName('');
            setLoading(false);
        }, (err) => {
            message.error('Failed to add ability group');
            setLoading(false);
        });
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            addNewGroup();
        }
    };

    const onDeleteAbility = (abilityId, groupId) => {
        Abilities.Delete(abilityId, (response) => {
            message.success('Ability deleted');
            setAbilityGroups(prevGroups => {
                return prevGroups.map(group => {
                    if (group.id === groupId) {
                        return {
                            ...group,
                            abilities: group.abilities.filter(ability => ability.id !== abilityId)
                        };
                    }
                    return group;
                });
            });
        }, (err) => {
            message.error('Failed to delete ability');
        });
    };

    const onDeleteGroup = (groupId) => {
        Abilities.DeleteGroup(groupId, (response) => {
            message.success('Ability group deleted');
            setAbilityGroups(abilityGroups.filter(group => group.id !== groupId));
        }, (err) => {
            message.error('Failed to delete ability group');
        });
    };

    const onEditAbility = (abilityId, newLabel, groupId, callback) => {
        Abilities.Update(abilityId, { label: newLabel }, (response) => {
            const _ability = response.data.data;
            message.success('Ability updated');
            setAbilityGroups(prevGroups => {
                return prevGroups.map(group => {
                    if (group.id === groupId) {
                        return {
                            ...group,
                            abilities: group.abilities.map(ability =>
                                ability.id === abilityId ? _ability : ability
                            )
                        };
                    }
                    return group;
                });
            });
            callback();
        }, (err) => {
            message.error('Failed to update ability');
            callback();
        });
    };

    const onEditGroup = (groupId, newGroupName, callback) => {
        Abilities.UpdateGroup(groupId, { name: newGroupName }, (response) => {
            message.success('Ability group updated');
            setAbilityGroups(prevGroups => {
                return prevGroups.map(group =>
                    group.id === groupId ? { ...group, name: newGroupName } : group
                );
            });
            callback();
        }, (err) => {
            message.error('Failed to update ability group');
            callback();
        });
    };

    const onAddAbility = (groupId, abilityName, callback) => {
        Abilities.Store({ group_id: groupId, label: abilityName }, (response) => {
            const newAbility = response.data.data.ability;
            setAbilityGroups(prevGroups => {
                return prevGroups.map(group => {
                    if (group.id === groupId) {
                        return {
                            ...group,
                            abilities: [...group.abilities, newAbility],
                        };
                    }
                    return group;
                });
            });
            callback();
        }, (err) => {
            message.error('Failed to add ability');
            callback();
        });
    };

    const onDragEnd = (result) => {
        const { source, destination, draggableId, type } = result;

        if (!destination) return;
        if (source.droppableId === destination.droppableId && source.index === destination.index) return;

        if (type === 'group') {
            const reorderedGroups = Array.from(abilityGroups);
            const [removed] = reorderedGroups.splice(source.index, 1);
            reorderedGroups.splice(destination.index, 0, removed);

            setAbilityGroups(reorderedGroups);

            Abilities.UpdateGroupOrder({
                groups: reorderedGroups.map((group, i) => ({ id: group.id, order: i }))
            }, (response) => {
                message.success('Group order updated');
            }, (err) => {
                message.error('Failed to update group order');
            });
        } else if (type === 'ability') {
            // Prevent drag-and-drop between groups
            if (source.droppableId !== destination.droppableId) {
                return;
            }

            const groupIndex = abilityGroups.findIndex(group => group.id.toString() === source.droppableId);
            const group = abilityGroups[groupIndex];
            const reorderedAbilities = Array.from(group.abilities);

            const [moved] = reorderedAbilities.splice(source.index, 1);
            reorderedAbilities.splice(destination.index, 0, moved);

            const updatedGroup = {
                ...group,
                abilities: reorderedAbilities,
            };
            const updatedGroups = [...abilityGroups];
            updatedGroups[groupIndex] = updatedGroup;

            setAbilityGroups(updatedGroups);

            Abilities.UpdateOrder({
                group_id: group.id,
                abilities: reorderedAbilities.map((ability, i) => ({ id: ability.id, order: i }))
            }, (response) => {
                message.success('Ability order updated');
            }, (err) => {
                message.error('Failed to update ability order');
            });
        }
    };

    return (
        <DefaultContainer className="manage-abilities mx-auto max-w-8xl py-2">
            <div className="text-xl font-bold text-black mb-4">Manage Abilities</div>
            <div className="mb-4 flex items-center">
                <Input
                    placeholder="New Group Name"
                    value={newGroupName}
                    onChange={(e) => setNewGroupName(e.target.value)}
                    onKeyPress={handleKeyPress}
                    style={{ width: '300px', marginRight: '10px' }}
                    disabled={loading}
                />
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={addNewGroup}
                    loading={loading}
                    disabled={loading}
                    className={"primary-button"}
                >
                    Add Group
                </Button>
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="all-groups" type="group">
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="ability-groups"
                        >
                            {abilityGroups.map((group, index) => (
                                <AbilityGroup
                                    key={group.id}
                                    group={group}
                                    index={index}
                                    onDeleteGroup={onDeleteGroup}
                                    onDeleteAbility={onDeleteAbility}
                                    onEditGroup={onEditGroup}
                                    onEditAbility={onEditAbility}
                                    onAddAbility={onAddAbility}
                                />
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </DefaultContainer>
    );
};

export default ManageAbilities;
