import React, { useState } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Input, Button, Popconfirm } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import AbilityItem from './AbilityItem';

const AbilityGroup = ({ group, index, onDeleteGroup, onDeleteAbility, onEditGroup, onEditAbility, onAddAbility }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [newGroupName, setNewGroupName] = useState(group.name);
    const [loading, setLoading] = useState(false);
    const [newAbilityName, setNewAbilityName] = useState('');

    const handleSave = () => {
        setLoading(true);
        onEditGroup(group.id, newGroupName, () => {
            setLoading(false);
            setIsEditing(false);
        });
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleCancel = () => {
        setIsEditing(false);
        setNewGroupName(group.name);
    };

    const handleAddAbility = () => {
        if (!newAbilityName.trim()) return;
        setLoading(true);
        onAddAbility(group.id, newAbilityName, () => {
            setNewAbilityName('');
            setLoading(false);
        });
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSave();
        }
    };

    const handleAbilityKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleAddAbility();
        }
    };

    const abilities = group.abilities || [];

    return (
        <Draggable draggableId={group.id.toString()} index={index}>
            {(provided) => (
                <div
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    className="group mb-4 rounded box"
                >
                    <div {...provided.dragHandleProps} className="group-header font-bold mb-1 flex justify-between items-center rounded-t px-3 border-b border-gray-200 pt-1 bg-gray-50">

                        {isEditing ? (
                            <div className="flex items-center py-2">
                                <Input
                                    value={newGroupName}
                                    onChange={(e) => setNewGroupName(e.target.value)}
                                    onKeyPress={handleKeyPress}
                                    size="large"
                                    style={{ width: '150px', marginRight: '10px' }}
                                    disabled={loading}
                                />
                                <Button
                                    type="primary"
                                    icon={<CheckOutlined />}
                                    size="small"
                                    onClick={handleSave}
                                    loading={loading}
                                    style={{ marginRight: '5px' }}
                                />
                                <Button
                                    type="default"
                                    icon={<CloseOutlined />}
                                    size="small"
                                    onClick={handleCancel}
                                    disabled={loading}
                                />
                            </div>
                        ) : (
                            <span className={"text-2xl patty"}>{group.name}</span>
                        )}

                        {!isEditing && (
                            <div className="flex">
                                {abilities.length === 0 && (
                                    <Popconfirm
                                        placement="rightTop"
                                        title="Are you sure to delete this group?"
                                        onConfirm={() => onDeleteGroup(group.id)}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button type="link" icon={<DeleteOutlined />} danger />
                                    </Popconfirm>
                                )}
                                <Button
                                    type="link"
                                    icon={<EditOutlined />}
                                    onClick={handleEdit}
                                />
                            </div>
                        )}
                    </div>
                    <Droppable droppableId={group.id.toString()} type="ability">
                        {(provided) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className="group-abilities p-3"
                            >
                                {abilities.map((ability, i) => (
                                    <AbilityItem
                                        key={ability.id}
                                        ability={ability}
                                        index={i}
                                        groupId={group.id}
                                        onDelete={onDeleteAbility}
                                        onEdit={onEditAbility}
                                    />
                                ))}
                                {abilities.length == 0?'There are no abilities in this group.':''}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                    <div className="flex items-center p-3 pt-0">
                        <Input
                            placeholder="New Ability Name"
                            value={newAbilityName}
                            onChange={(e) => setNewAbilityName(e.target.value)}
                            onKeyPress={handleAbilityKeyPress}
                            size="middle"
                            style={{ width: '200px', marginRight: '10px' }}
                            disabled={loading}
                        />
                        <Button
                            type="primary"
                            onClick={handleAddAbility}
                            loading={loading}
                            disabled={loading}
                            className={"primary-button"}
                        >
                            <div className={"flex flex-row items-center"}>
                                <PlusOutlined className={"icon"} />
                                Add Ability
                            </div>
                        </Button>
                    </div>
                </div>
            )}
        </Draggable>
    );
};

export default AbilityGroup;
