import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Table, Button, notification, InputNumber, Modal, Form } from 'antd';
import OrderService from '../../../../../http/OrderService';
import SkeletonWrapper from '../../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../../components/skeletons/GenericSkeleton';
import DefaultContainer from '../../../../../components/DefaultContainer';
import { ReloadOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faEye, faCheckCircle, faTasks, faTruck, faClipboardCheck } from "@fortawesome/free-solid-svg-icons";

const BranchOrders = () => {
    const { branchId } = useParams();
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const [branch, setBranch] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [refreshing, setRefreshing] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (branchId) {
            fetchBranchOrders(branchId);
        }
    }, [branchId]);

    const fetchBranchOrders = (branchId) => {
        setRefreshing(true);
        if (!orders) setLoading(true);
        OrderService.getBranchOrders(branchId,
            response => {
                const data = response.data.data;
                setOrders(data.orders);
                setBranch(data.branch);
                setLoading(false);
                setRefreshing(false);
            },
            error => {
                notification.error({
                    message: 'Error',
                    description: 'Failed to load orders',
                });
                setLoading(false);
                setRefreshing(false);
            });
    };

    const handleStatusChange = (order) => {
        setSelectedOrder(order);
        form.setFieldsValue({ received_amount: order.sent_amount || order.ordered_amount }); // Set the form's value based on the selected order
        setIsModalVisible(true);
    };

    const handleModalOk = () => {
        form.validateFields().then(values => {
            OrderService.updateOrderStatus(selectedOrder.id, { status: 'received', received_amount: values.received_amount },
                response => {
                    notification.success({
                        message: 'Success',
                        description: 'Order status updated successfully',
                    });
                    fetchBranchOrders(branchId);
                    setIsModalVisible(false);
                    form.resetFields();
                },
                error => {
                    notification.error({
                        message: 'Error',
                        description: 'Failed to update order status',
                    });
                });
        }).catch(info => {
            console.log('Validate Failed:', info);
        });
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };

    const statusColorMap = {
        pending: '#ececec',
        seen: '#e4f9ff',
        confirmed: '#90EE90',
        preparing: '#FFDAB9',
        shipped: '#fee0ff',
        received: 'rgba(174,255,171,0.28)'
    };

    const statusIconMap = {
        pending: faClock,
        seen: faEye,
        confirmed: faCheckCircle,
        preparing: faTasks,
        shipped: faTruck,
        received: faClipboardCheck
    };

    const columns = [
        {
            title: 'Inventory Group',
            dataIndex: ['branch_inventory_group', 'name'],
            key: 'group_name',
            render: (text, record) => (
                <div className={`patty text-2xl ${record.status === 'cancelled' ? 'line-through' : ''}`}>
                    {text}
                </div>
            )
        },
        {
            title: 'Ordered Amount',
            dataIndex: 'ordered_amount',
            key: 'ordered_amount',
            render: (text, record) => (
                <div className={`font-bold ${record.status === 'cancelled' ? 'line-through' : ''}`}>
                    {text}{record.branch_inventory_group.unit_type}
                </div>
            )
        },
        {
            title: 'Ordered At',
            dataIndex: 'ordered_at',
            key: 'ordered_at',
            render: (text, record) => (
                <div className={record.status === 'cancelled' ? 'line-through' : ''}>
                    {text}
                </div>
            )
        },
        {
            title: 'Vendor',
            dataIndex: ['inventory_vendor', 'name'],
            key: 'vendor_name',
            render: (text, record) => (
                <div className={record.status === 'cancelled' ? 'line-through' : ''}>
                    {text}
                </div>
            )
        },
        {
            title: 'Sent At',
            dataIndex: 'sent_at',
            key: 'sent_at',
            render: (text, record) => (
                <div className={record.status === 'cancelled' ? 'line-through' : ''}>
                    {text}
                </div>
            )
        },
        {
            title: 'Sent Amount',
            dataIndex: 'sent_amount',
            key: 'sent_amount',
            render: (text, record) => (
                text &&
                <div className={`font-bold ${record.status === 'cancelled' ? 'line-through' : ''}`}>
                    {text}{record.branch_inventory_group.unit_type}
                </div>
            )
        },
        {
            title: 'Received At',
            dataIndex: 'received_at',
            key: 'received_at',
            render: (text, record) => (
                <div className={record.status === 'cancelled' ? 'line-through' : ''}>
                    {text}
                </div>
            )
        },
        {
            title: 'Received Amount',
            dataIndex: 'received_amount',
            key: 'received_amount',
            render: (text, record) => (
                text &&
                <div className={`font-bold ${record.status === 'cancelled' ? 'line-through' : ''}`}>
                    {text}{record.branch_inventory_group.unit_type}
                </div>
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <div className="flex flex-col items-start">
                    <div style={{ backgroundColor: statusColorMap[text] }} className={`w-[150px] uppercase text-sm font-bold py-1 text-left pl-3 flex flex-row items-center justify-start `}>
                        <FontAwesomeIcon icon={statusIconMap[text]} className="mr-2" />
                        {text}
                    </div>
                </div>
            )
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <div>
                    {record.status !== 'received' && record.status !== 'cancelled' && (
                        <Button size="small" onClick={() => handleStatusChange(record)}>Mark as Received</Button>
                    )}
                </div>
            )
        }
    ];

    return (
        <DefaultContainer>
            <div className="patty text-2xl font-bold mb-4 flex justify-between items-center">
                <span>Branch Orders {branch && <span> - {branch.name}</span>}</span>
                <Button icon={<ReloadOutlined />} loading={refreshing} onClick={() => fetchBranchOrders(branchId)}>Refresh</Button>
            </div>
            <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                <Table
                    columns={columns}
                    dataSource={orders}
                    pagination={false}
                    rowKey="id"
                    rowClassName={(record) => record.status === 'cancelled' ? 'line-through' : ''}
                />
            </SkeletonWrapper>
            <Modal
                title="Receive Order"
                visible={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
            >
                <Form form={form}>
                    <Form.Item
                        name="received_amount"
                        label="Received Amount"
                        rules={[{ required: true, message: 'Please input the received amount!' }]}
                    >
                        {selectedOrder &&
                            <InputNumber min={0} style={{ width: '130px' }} addonAfter={selectedOrder.branch_inventory_group.unit_type} />
                        }
                    </Form.Item>
                </Form>
            </Modal>
        </DefaultContainer>
    );
};

export default BranchOrders;
