import axios from 'axios';
import {getAuthenticationHeaders} from './Headers';

const Menu = {
    Index: (successCb, failCb) => {
        axios.get('/categories', getAuthenticationHeaders()).then(response => {
            successCb(response);
        }).catch(err => {
            failCb(err);
        });
    },
};

export default Menu;
